import {useEffect, useState, createContext, useContext} from "react";
import { content as englishContent } from "../content/english";
import { content as arabicContent } from "../content/arabic";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button} from "@nextui-org/react";

const LanguageContext = createContext({
    language: "en",
    dictionary: {},
    changeLanguage: (language) => {},
});

const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState("en");
    const [dictionary, setDictionary] = useState(englishContent);
    const [languageSelectionModalOpen, setLanguageSelectionModalOpen] = useState(false);

    const getContent = (language) => {
        switch (language) {
            case "en":
                return englishContent;
            case "ar":
                return arabicContent;
            default:
                return englishContent;
        }
    }

    useEffect(() => {
        const language = localStorage.getItem("language");
        if (language) {
            setLanguage(language);
            setDictionary(getContent(language))
        } else {
            // Check The URL, if its was.ae
            const url = window.location.href;
            if (url.includes("was.ae")) {
                setLanguageSelectionModalOpen(true);
            }
        }
    }, []);

    const changeLanguage = (language) => {
        if (languageSelectionModalOpen) {
            setLanguageSelectionModalOpen(false);
        }
        localStorage.setItem("language", language);
        setLanguage(language);
        setDictionary(getContent(language))
    };

    return (
        <LanguageContext.Provider value={{ language, dictionary, changeLanguage }}>
            <>
            {
                dictionary.contact && (
                    <div className={language === 'ar' ? 'arabic-text' : ''}>
                        {children}
                    </div>
                )
            }

                <Modal isOpen={languageSelectionModalOpen} closable={true} disableBackdropClick={true} placement="center" onClose={() => {
                    setLanguageSelectionModalOpen(false);
                }}>
                    <ModalContent>
                        <ModalHeader>
                            Language Preference
                        </ModalHeader>
                        <ModalBody>
                            <p>
                                Since this is your first time visiting our website, please choose your preferred language.
                            </p>
                            <p className='arabic-text'>
                                بما أن هذه هي المرة الأولى التي تزور فيها موقعنا ، يرجى اختيار اللغة المفضلة لديك.
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button auto onClick={() => changeLanguage("en")}>English</Button>
                            <Button auto onClick={() => changeLanguage("ar")}>
                                <span className='arabic-text'>عربى</span>
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        </LanguageContext.Provider>
    );
}

const useLanguage = () => {
    const { language, dictionary, changeLanguage } = useContext(LanguageContext);
    return { language, dictionary, changeLanguage };
}

export { LanguageContext, LanguageProvider, useLanguage };