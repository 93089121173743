export const content = {
    navbar: {
        home: "Home",
        about: "About Us",
        services: "Services",
        gallery: "Gallery",
        contact: "Contact Us",
        language: "Language",
    },
    home: {
        titleLine1: "We work on the spirit of the land",
        titleLine2: "towards our green Emirates.",
        subtitle: "- Wahat Al Sahraa",
        button: "Discover Our Services",
    },
    about: {
        title: "About Us",
        paragraph1: "Wahat Al Sahraa is a distinguished landscaping company based in Dubai, renowned for its extensive range of professional services in both commercial and domestic realms. With years of experience under our belt, we have become a trusted name in the industry, known for our commitment to quality, innovation, and customer satisfaction.",
        paragraph2: "As a family-owned and operated business, we take great pride in establishing and maintaining warm, friendly relationships with our clients. Our forte lies in our versatility, offering a wide array of services including landscaping, swimming pools, automatic irrigation systems, hardscaping, lighting, water features, and planting.",
        paragraph3: "Our approach to landscaping is holistic, focusing not only on the aesthetic enhancement of outdoor spaces but also on their functionality and sustainability. We are experts in both hard landscaping, which gives structure and form to outdoor areas, and soft landscaping, which involves the artistic arrangement of plants and greenery."
    },
    services: {
        title: "Our Services",
        paragraph1: "Our commitment extends beyond the creation of beautiful landscapes to their ongoing care and maintenance. We offer comprehensive monthly maintenance packages, including services like lawn mowing, debris removal, weeding, trimming, fertilizing, spraying, pruning, and watering, each tailored to the specific needs of our clients.",
        paragraph2: "At Wahat Al Sahraa, we believe that every project is an opportunity to create something extraordinary. We invite you to experience the dedication and expertise that make us a leading landscaping provider in Dubai.",
        cards: [
            {
                title: "Landscaping",
                description: "Tailoring unique outdoor environments to individual tastes and preferences.",
            },
            {
                title: "Swimming Pools",
                description: "Seamlessly integrating custom-designed pools into the landscape.",
            },
            {
                title: "Automatic Irrigation Systems",
                description: "Implementing efficient and eco-friendly watering solutions.",
            },
            {
                title: "Hardscaping",
                description: "Constructing durable, visually appealing structural elements.",
            },
            {
                title: "Lighting",
                description: "Strategically placing lighting to enhance the beauty and usability of outdoor areas.",
            },
            {
                title: "Water Features",
                description: "Creating serene and inviting spaces with waterfalls and fountains.",
            },
            {
                title: "Planting",
                description: "Cultivating harmonious and sustainable garden spaces.",
            },
        ],
    },
    gallery: {
        title: "Gallery",
    },
    contact: {
        title: "Contact Us",
        description: "If you are interested in our services, please fill out the form below and we will get back to you as soon as possible.",
        fields: {
            fullName: "Full Name",
            email: "Email",
            phoneNum: "Phone Number",
            interestedIn: "Interested In",
            message: "Message",
            submit: "Submit",
        },
        services: ["Landscaping", "Swimming Pools", "Automatic Irrigation Systems", "Hardscaping", "Lighting", "Water Features", "Planting", "Other"],
    }
}