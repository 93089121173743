export const content = {
    navbar: {
        home: "الرئيسية",
        about: "من نحن",
        services: "الخدمات",
        gallery: "المعرض",
        contact: "اتصل بنا",
        language: "اللغة",
    },
    home: {
        titleLine1: "نعمل بروح الأرض",
        titleLine2: "نحو إماراتنا الخضراء.",
        subtitle: "- واحة الصحرااء",
        button: "اكتشف خدماتنا",
    },
    about: {
        title: "من نحن",
        paragraph1: "واحة الصحراء هي شركة تنسيق حدائق متميزة في دبي، معروفة بمجموعتها الواسعة من الخدمات المهنية في المجالين التجاري والمنزلي. مع سنوات من الخبرة، أصبحنا اسما موثوقا في الصناعة، معروفين بالتزامنا بالجودة والابتكار ورضا العملاء.",
        paragraph2: "كشركة عائلية، نفخر بإقامة والحفاظ على علاقات ودية مع عملائنا. تتميز خدماتنا بتنوعها، حيث نقدم مجموعة واسعة من الخدمات بما في ذلك تنسيق الحدائق، المسابح، أنظمة الري الأوتوماتيكية، الأعمال الحجرية، الإضاءة، ميزات المياه والزراعة.",
        paragraph3: "نهجنا في تنسيق الحدائق هوليستي، يركز ليس فقط على تحسين الجماليات للمساحات الخارجية ولكن أيضا على وظيفتها واستدامتها. نحن خبراء في كل من تنسيق الحدائق الصلبة، التي تعطي الهيكل والشكل للمناطق الخارجية، وتنسيق الحدائق الناعمة، التي تشمل الترتيب الفني للنباتات والخضرة."
    },
    services: {
        title: "خدماتنا",
        paragraph1: "التزامنا يتعدى خلق مناظر طبيعية جميلة إلى العناية المستمرة والصيانة. نقدم حزم صيانة شهرية شاملة، تشمل خدمات مثل قص العشب، إزالة الحطام، إزالة الأعشاب الضارة، التقليم، التسميد، الرش، التقليم، والري، كل منها مصمم خصيصا لاحتياجات عملائنا.",
        paragraph2: "في واحة الصحراء، نعتقد أن كل مشروع هو فرصة لخلق شيء استثنائي. ندعوك لتجربة الإخلاص والخبرة التي تجعلنا مزود تنسيق الحدائق الرائد في دبي.",
        cards: [
            {
                title: "تنسيق الحدائق",
                description: "تصميم بيئات خارجية فريدة تتناسب مع الأذواق والتفضيلات الفردية.",
            },
            {
                title: "المسابح",
                description: "دمج المسابح المصممة حسب الطلب بسلاسة في المناظر الطبيعية.",
            },
            {
                title: "أنظمة الري الأوتوماتيكية",
                description: "تطبيق حلول الري الفعالة والصديقة للبيئة.",
            },
            {
                title: "الأعمال الحجرية",
                description: "بناء عناصر هيكلية جذابة ومتينة.",
            },
            {
                title: "الإضاءة",
                description: "وضع الإضاءة استراتيجيا لتعزيز جمال وقابلية استخدام المساحات الخارجية.",
            },
            {
                title: "ميزات المياه",
                description: "خلق مساحات هادئة وجذابة مع الشلالات والنوافير.",
            },
            {
                title: "الزراعة",
                description: "تنمية مساحات حدائق متناغمة ومستدامة.",
            },
        ],
    },
    gallery: {
        title: "معرض الصور",
    },
    contact: {
        title: "اتصل بنا",
        description: "إذا كنت مهتمًا بخدماتنا، يرجى تعبئة النموذج أدناه وسنعود إليك في أقرب وقت ممكن.",
        fields: {
            fullName: "الاسم الكامل",
            email: "البريد الإلكتروني",
            phoneNum: "رقم الهاتف",
            interestedIn: "مهتم بـ",
            message: "الرسالة",
            submit: "إرسال",
        },
        services: ["تنسيق الحدائق", "المسابح", "أنظمة الري الأوتوماتيكية", "الأعمال الحجرية", "الإضاءة", "ميزات المياه", "الزراعة", "أخرى"],
    }
}
