import './navbar.scss';
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger} from "@nextui-org/react";
import MenuIcon from "../icons/MenuIcon";
import WhatsappIcon from "../icons/WhatsappIcon";
import {useEffect, useState} from "react";
import CloseIcon from "../icons/CloseIcon";
import { motion } from "framer-motion"
import {useLanguage} from "../../context/LanguageProvider";

const Navbar = ({ activeSection, scrollToSection }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const language = useLanguage();
    const content = language.dictionary?.navbar || {};

    const onMobileMenuButtonClick = () => {
        setMobileMenuOpen(true);
    }

    const onCloseButtonClicked = () => {
        setMobileMenuOpen(false);
    }

    const openWhatsapp = () => {
        window.open('https://wa.link/c4anez', '_blank');
    }

    const onScrollClicked = (destination) => {
        if (mobileMenuOpen) {
            setMobileMenuOpen(false);
        }
        scrollToSection(destination);
    }

    return (
        <>
            <div className="navbarWrapper">
                <nav className='custom-container'>
                    <ul>
                        <li>
                            <a
                               onClick={() => onScrollClicked('homeSection')}
                               className={activeSection === 'homeSection' ? 'active' : ''}
                            >{content.home}</a>
                        </li>
                        <li>
                            <a
                                onClick={() => onScrollClicked('aboutUsSection')}
                                className={activeSection === 'aboutUsSection' ? 'active' : ''}
                            >{content.about}</a>
                        </li>
                        <li>
                            <a
                                onClick={() => onScrollClicked('servicesSection')}
                                 className={activeSection === 'servicesSection' ? 'active' : ''}
                            >{content.services}</a>
                        </li>
                    </ul>

                    <Button onClick={onMobileMenuButtonClick} className="mobile-only" isIconOnly variant='light' aria-label="Like">
                        <MenuIcon />
                    </Button>

                    <div className='logo'>
                        <img src="/images/logo.png" alt="logo" />
                    </div>

                    <Button
                        onClick={openWhatsapp}
                        className="mobile-only" isIconOnly variant='light' aria-label="Like">
                        <WhatsappIcon />
                    </Button>

                    <ul>
                        <li>
                            <a
                                onClick={() => onScrollClicked('gallerySection')}
                               className={activeSection === 'gallerySection' ? 'active' : ''}
                            >{content.gallery}</a>
                        </li>
                        <li>
                            <a
                                onClick={() => onScrollClicked('contactUsSection')}
                                className={activeSection === 'contactUsSection' ? 'active' : ''}
                            >{content.contact}</a>
                        </li>
                        <li>
                            <Dropdown>
                                <DropdownTrigger>
                                    <a href='#'>{content.language}</a>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Static Actions">
                                    <DropdownItem onClick={() => {
                                        language.changeLanguage('en');
                                    }} key="en">English</DropdownItem>
                                    <DropdownItem onClick={() => {
                                        language.changeLanguage('ar');
                                    }} key="ar">
                                        <span className='arabic-text'>العربية</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    </ul>
                </nav>
            </div>

            {
                mobileMenuOpen && (
                    <motion.div
                        initial={{ x: -2000, y: 0, zIndex: 9999 }}
                        animate={{ x: 0, y: 0, zIndex: 9999 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className='mobileMenu mobile-only'>
                            <Button onClick={onCloseButtonClicked} style={{ color: 'white '}} className="closeButton" isIconOnly variant='light' aria-label="Like">
                                <CloseIcon />
                            </Button>

                            <div className='logo'>
                                <img src="/images/logo.png" alt="logo" />
                            </div>
                            <h2>Wahat Al Sahraa</h2>

                            <ul>
                                <li>
                                    <a
                                        onClick={() => onScrollClicked('homeSection')}
                                        className={activeSection === 'homeSection' ? 'active' : ''}
                                    >{content.home}</a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => onScrollClicked('aboutUsSection')}
                                        className={activeSection === 'aboutUsSection' ? 'active' : ''}
                                    >{content.about}</a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => onScrollClicked('servicesSection')}
                                        className={activeSection === 'servicesSection' ? 'active' : ''}
                                    >{content.services}</a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => onScrollClicked('gallerySection')}
                                        className={activeSection === 'gallerySection' ? 'active' : ''}
                                    >{content.gallery}</a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => onScrollClicked('contactUsSection')}
                                        className={activeSection === 'contactUsSection' ? 'active' : ''}
                                    >{content.contact}</a>
                                </li>
                                <li>
                                    <Dropdown>
                                        <DropdownTrigger>
                                            <a href='#'>{content.language}</a>
                                        </DropdownTrigger>
                                        <DropdownMenu aria-label="Static Actions">
                                            <DropdownItem onClick={() => {
                                                language.changeLanguage('en');
                                                if (mobileMenuOpen) {
                                                    setMobileMenuOpen(false);
                                                }
                                            }} key="en">English</DropdownItem>
                                            <DropdownItem onClick={() => {
                                                language.changeLanguage('ar');
                                                if (mobileMenuOpen) {
                                                    setMobileMenuOpen(false);
                                                }
                                            }} key="ar">
                                                <span className='arabic-text'>العربية</span>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>
                            </ul>
                        </div>
                    </motion.div>
                )
            }
        </>
    )
}

export default Navbar;
