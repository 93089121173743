import Navbar from "../../components/navbar/Navbar";
import {motion} from "framer-motion";
import './home.scss';
import {Button, Input, Select, SelectItem, Textarea} from "@nextui-org/react";
import Footer from "../../components/footer/Footer";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Import marker icons
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {useEffect, useRef, useState} from "react";
import {useLanguage} from "../../context/LanguageProvider";

// Fix the marker icon issue
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

const HomePage = () => {
    const homeSection = useRef();
    const aboutUsSection = useRef();
    const servicesSection = useRef();
    const gallerySection = useRef();
    const contactUsSection = useRef();

    const [activeSection, setActiveSection] = useState();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            { threshold: 0.5 } // Adjust threshold to your needs
        );

        // Observe each section
        observer.observe(homeSection.current);
        observer.observe(aboutUsSection.current);
        observer.observe(servicesSection.current);
        observer.observe(gallerySection.current);
        observer.observe(contactUsSection.current);

        return () => {
            // Clean up the observer on unmount
            observer.disconnect();
        };
    }, []);

    const position = [25.262, 55.328];
    const language = useLanguage();

    const homeContent = language.dictionary?.home || {};
    const aboutUsContent = language.dictionary?.about || {};
    const servicesContent = language.dictionary?.services || {};
    const galleryContent = language.dictionary?.gallery || {};
    const contactUsContent = language.dictionary?.contact || {};

    const scrollToSection = (sectionId) => {
        const sectionRef = { homeSection, aboutUsSection, servicesSection, gallerySection, contactUsSection }[sectionId];
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Navbar activeSection={activeSection} scrollToSection={scrollToSection} />

            <div ref={homeSection} id='homeSection' style={{backgroundImage: `url('images/background/uae-burj-khalifa.jpg')`}} className='mainSection'>
                <div className='topFilter'></div>
                <div className='textSection'>
                    <motion.div
                        initial={{x: -100, y: -1000}}
                        animate={{x: 0, y: 0}}
                        transition={{type: "spring", stiffness: 100}}
                    >
                        <h1>
                            {homeContent.titleLine1}<br/>
                            {homeContent.titleLine2}
                        </h1>
                        <h2>{homeContent.subtitle}</h2>
                        <Button className='actionButton' color='white' variant='bordered' size='large'
                                onClick={() => scrollToSection('servicesSection')}
                        >
                            {homeContent.button}
                        </Button>
                    </motion.div>

                </div>
                <img className='flag1' src="/images/uae-flag.png" alt="UAE Flag"/>
                <img className='flag2' src="/images/uae-flags.png" alt="UAE Flags"/>
            </div>

            <div ref={aboutUsSection} id='aboutUsSection' className="section primarySection">
                <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-1 gap-4 custom-container">
                    <div>
                        <h2>{aboutUsContent.title}</h2>

                        <p>
                            {aboutUsContent.paragraph1}<br/><br/>
                        </p>

                        <p>
                            {aboutUsContent.paragraph2}<br/><br/>
                        </p>

                        <p>
                            {aboutUsContent.paragraph3}<br/><br/>
                        </p>
                    </div>
                    <div className='flex justify-end items-center'>
                        <img src="/images/about-us.jpg" alt="About Us"/>
                    </div>
                </div>
            </div>

            <div className='section greenBackground'>
                <div className='text-white text-center custom-container'>
                    <h2>{servicesContent.title}</h2>
                    <p>
                        {servicesContent.paragraph1}<br/><br/>
                    </p>
                    <p>
                        {servicesContent.paragraph2}<br/><br/>
                    </p>

                    <div id='servicesSection' ref={servicesSection} className="flex justify-center flex-wrap gap-10 pb-20">
                        <div className='serviceCard'>
                            <h3>{servicesContent.cards[0]?.title}</h3>
                            <img src="/images/services/landscaping.jpg" alt="Landscaping"/>
                            <p>
                                {servicesContent.cards[0]?.description}
                            </p>
                        </div>

                        <div className='serviceCard'>
                            <h3>{servicesContent.cards[1]?.title}</h3>
                            <img src="/images/services/swimming-pools.jpg" alt="Swimming Pool"/>
                            <p>
                                {servicesContent.cards[1]?.description}
                            </p>
                        </div>

                        <div className='serviceCard'>
                            <h3>{servicesContent.cards[2]?.title}</h3>
                            <img src="/images/services/irrigation-system.jpg" alt="Irrigation System"/>
                            <p>
                                {servicesContent.cards[2]?.description}
                            </p>
                        </div>

                        <div className='serviceCard'>
                            <h3>{servicesContent.cards[3]?.title}</h3>
                            <img src="/images/services/hardscaping.jpg" alt="Hardscaping"/>
                            <p>
                                {servicesContent.cards[3]?.description}
                            </p>
                        </div>

                        <div className='serviceCard'>
                            <h3>{servicesContent.cards[4]?.title}</h3>
                            <img src="/images/services/lightning.jpg" alt="Lighting"/>
                            <p>
                                {servicesContent.cards[4]?.description}
                            </p>
                        </div>

                        <div className='serviceCard'>
                            <h3>{servicesContent.cards[5]?.title}</h3>
                            <img src="/images/services/fountains.jpg" alt="Fountains"/>
                            <p>
                                {servicesContent.cards[5]?.description}
                            </p>
                        </div>

                        <div className='serviceCard'>
                            <h3>{servicesContent.cards[6]?.title}</h3>
                            <img src="/images/services/plants.jpg" alt="Plants"/>
                            <p>
                                {servicesContent.cards[6]?.description}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={gallerySection} id='gallerySection' className='section custom-container primarySection'>
                <h2>{galleryContent.title}</h2>
                <div className='gallery grid grid-cols-1 md:grid-cols-3 grid-rows-1 gap-4'>
                    <img src="/images/gallery/1.png" alt="Gallery 1"/>
                    <img src="/images/gallery/2.png" alt="Gallery 2"/>
                    <img src="/images/gallery/3.png" alt="Gallery 3"/>
                    <img src="/images/gallery/4.png" alt="Gallery 4"/>
                    <img src="/images/gallery/5.png" alt="Gallery 5"/>
                    <img src="/images/gallery/6.png" alt="Gallery 6"/>
                    <img src="/images/gallery/7.png" alt="Gallery 7"/>
                    <img src="/images/gallery/8.png" alt="Gallery 8"/>
                    <img src="/images/gallery/9.png" alt="Gallery 9"/>
                </div>
            </div>

            <div ref={contactUsSection} id='contactUsSection' className='section greenBackground'>
                <div className='text-white'>
                    <h2 className='text-center'>{contactUsContent.title}</h2>

                    <div className="mt-10 grid grid-cols-1 md:grid-cols-2 grid-rows-1 gap-4 custom-container">
                        <div>
                            <p>
                                {contactUsContent.description}
                            </p>
                            <div className='flex flex-col gap-5 text-black'>
                                <div className="mt-10 flex w-full flex-wrap md:flex-nowrap gap-4">
                                    <Input type="text" label={contactUsContent.fields.fullName}/>
                                    <Input type="email" label={contactUsContent.fields.email} />
                                </div>
                                <Select
                                    label={contactUsContent.fields.interestedIn}
                                >
                                    {
                                        contactUsContent.services.map((service, index) => {
                                            return (
                                                <SelectItem key={`service-${index}`} value={service}>
                                                    {service}
                                                </SelectItem>
                                            )
                                        })
                                    }
                                </Select>
                                <Input type="tel" label={contactUsContent?.fields.phoneNum}/>
                                <Textarea
                                    label={contactUsContent.fields.message}
                                    placeholder="..."
                                />

                                <Button disabled={true} className='actionButton' variant='solid' size='large'>
                                    {contactUsContent.fields.submit}
                                </Button>
                            </div>
                        </div>

                        <div className='pt-5'>
                            <MapContainer center={position} zoom={13} style={{ height: '400px', width: '100%', zIndex: '100' }}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                <Marker position={position} />
                            </MapContainer>
                            <div className='flex flex-wrap gap-5 justify-between items-center mt-10'>
                                <span>
                                <strong>Email: </strong>
                                <a href="mailto:info@was.ae">
                                    info@was.ae
                                </a>
                            </span>
                                <span>
                                <strong>Phone:</strong> +971 50 772 9257
                            </span>
                                <span>
                                <strong>Address:</strong><br/>
                                    Abu Baker Al Siddique Rd <br/>
                                    Deira - Dubai, UAE
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default HomePage;
