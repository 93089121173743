import './footer.scss';
import {Button} from "@nextui-org/react";
import LinkedInIcon from "../icons/LinkedInIcon";
import InstagramIcon from "../icons/InstagramIcon";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className='footer p-20'>
            <div className='flex justify-center gap-10'>
                <Button style={{ color: 'white '}} isIconOnly variant='light' aria-label="Linked In">
                    <LinkedInIcon />
                </Button>

                <Button style={{ color: 'white '}} isIconOnly variant='light' aria-label="Linked In">
                    <InstagramIcon />
                </Button>
            </div>
            <p className='text-center text-white'>@ {currentYear} All Rights Reserved. Wahat Al Sahraa</p>
        </footer>
    )
}

export default Footer;
