import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";

// Pages
import HomePage from "./pages/home/HomePage";
import {NextUIProvider} from "@nextui-org/react";
import {LanguageProvider} from "./context/LanguageProvider";

function App() {
  return (
      <NextUIProvider>
          <LanguageProvider>
              <HomePage />
          </LanguageProvider>
      </NextUIProvider>
  );
}

export default App;
